import React, { useState } from 'react';

export default function Evaluate() {
  const [rows, setRows] = useState([{ question: '', answer: '' }]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const addNewRow = () => {
    setRows([...rows, { question: '', answer: '' }]);
  };

  const generateQA = async (index) => {
    try {
      const response = await fetch('/generate_qa/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ document_index: index }), // Pass index for simplicity
      });

      if (!response.ok) {
        throw new Error('Failed to generate question and answer');
      }

      const data = await response.json();
      const updatedRows = [...rows];
      updatedRows[index].question = data.question;
      updatedRows[index].answer = data.answer;
      setRows(updatedRows);
    } catch (error) {
      console.error('Error generating Q&A:', error);
      alert('Error generating question and answer');
    }
  };

  return (
    <div className="evaluate-container">
      <h1>Generate Synthetic Questions and Answers</h1>
      <table className="qa-table">
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  value={row.question}
                  placeholder="Question"
                  onChange={(e) => handleInputChange(index, 'question', e.target.value)}
                />
              </td>
              <td>
                <textarea
                  value={row.answer}
                  placeholder="Answer"
                  onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => generateQA(index)}>Generate</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={addNewRow}>Add Row</button>
    </div>
  );
}

