import NavBar from './NavBar'
import { Outlet } from 'react-router-dom'

export default function Root () {
  return (
    <>
      <NavBar />
      <main className='flex-shrink-0' style={{ paddingTop: '56px' }}>
        <div className='container'>
          <Outlet />
        </div>
      </main>

    </>
  )
}
